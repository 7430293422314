/* .navTest {
  position: fixed;
  top: 0px;
  left: 244px;
  width: 100%;
  display: flex;
  border-left: 2px solid white;
} */

.nav-bar-container {
  /* background-color: #023047; */

  background-color: #1f2937;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-info-container {
  height: 60px;
  display: flex;
  position: static;
}

.nav-bar-admin-profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  color: white;
}

/* button {
  border: 2px solid green;
  height: 50px;
  width: 70px;
  border-radius: 4px;
  border: none;
  font-size: 30px;
} */

.toggleBtn {
  height: 50px;
  width: 70px;
  border-radius: 4px;
  border: none;
  font-size: 30px;
}
