.roomcode-form {
  /* border: 2px solid red; */
}

.roomcode-form input {
  height: 100%;
  /* width: 100%; */
  border: 1px solid gray;
  border-right: none;
  border-radius: 4px 0px 0px 4px;
  outline: none;
}

.roomcode-form input:focus {
  outline: none;
  border: none;
}

.roomcode-form button {
  border-radius: 0px 4px 4px 0px;
  width: 80px;
}

.roomcode-data-container {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
}

.roomcode-creator-data {
  /* border: 2px solid green; */
  padding: 8px 10px;
  background-color: rgba(218, 220, 235, 0.75);
  /* background-color: rgba(250, 112, 146, 0.15); */
  box-shadow: 1px 1px 3px black;
}
.roomcode-acceptor-data {
  /* border: 2px solid green; */
  padding: 8px 10px;
  /* background-color: rgba(227, 213, 202, 0.75); */
  background-color: rgba(246, 130, 0, 0.15);
  box-shadow: 1px 1px 3px black;
}

.roomcode-creator-data > h2,
.roomcode-acceptor-data > h2 {
  /* border: 2px solid gold; */
  text-align: center;
}

.roomcode-creator-data > div > p,
.roomcode-acceptor-data > div > p {
  font-size: 16px;
}

.roomcode-creator-data > div > p span:first-child,
.roomcode-acceptor-data > div > p span:first-child {
  font-weight: bold;
}

.roomcode-creator-data > div > p span:last-child,
.roomcode-acceptor-data > div > p span:last-child {
  color: rgb(18, 111, 158);
  margin-left: 3px;
}
