// Color Functions Mixins

// Solid tab variations
@mixin tab-solid-variant($color) {
  .nav-link {
    &.active {
      background: $color;
    }
  }
}
// Minimal tab variations
@mixin tab-minimal-variant($color) {
  .nav-tabs {
    .nav-item {
      .nav-link {
        &.active {
          color: $color;
        }
      }
    }
  }
}
// Sider color variations
@mixin slider-color-variant($color) {
  .noUi-base {
    .noUi-connect {
      background: $color;
    }

    .noUi-origin {
      background: $color;

      .noUi-handle {
        &:after {
          background: $color;
        }

        .noUi-tooltip {
          background: $color;
          color: $white;
        }
      }
    }
  }
}
// Breadcrumb color variations
@mixin breadcrumb-inverse-variant($color) {
  background: rgba($color, 0.25);
  border-color: $color;

  .breadcrumb-item {
    color: $color;

    &:before {
      color: inherit;
    }

    a {
      color: inherit;
    }
  }

  &.breadcrumb-custom {
    background: transparent;

    .breadcrumb-item {
      background: lighten($color, 25%);

      a {
        color: $color;
        border: none;

        &:before {
          border-left-color: lighten($color, 25%);
        }
      }

      span {
        color: $color;
      }

      &:last-child {
        background: transparent;
      }
    }
  }
}

// .pagination {
//   border: 2px solid goldenrod;
//   height: 60px !important;
//   background: rgb(70, 164, 210);
// }
// .page-item {
//   border: 2px solid red;
//   background: green;
//   height: 50px !important;
//   &.active {
//     border: 2px dashed black;
//     background: blue;
//   }
// }

// .page-link {
//   border: 2px solid blue;
//   background: yellow;
// }
// Pagination variations
@mixin pagination-variants($color) {
  .page-item {
    &.active {
      .page-link {
        background: $color;
        border-color: $color;
      }
    }

    .page-link {
      &:hover {
        background: lighten($color, 5%);
        border-color: $color;
        color: $white;
      }
    }
  }
}
// Accordion Inverse variations
@mixin accordion-inverse-variant($color) {
  .card {
    background: rgba($color, 0.16);
    color: $color;
    border: none;

    .card-header {
      color: $color;
      border: none;

      a,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      span {
        color: $color;
      }

      a {
        border-color: $color;

        &:before {
          color: $color;
        }

        &[aria-expanded="true"] {
          border-bottom: 1px solid rgba($color, 0.16);
        }

        &[aria-expanded="false"] {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}
