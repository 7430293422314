/* Sidebar */

.sidebar {
  // border: 2px solid red;
  // box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  min-height: calc(100vh - #{$navbar-height});
  // background-color: rgba(0, 27, 11, 0.734);
  box-sizing: border-box;
  // background-color: #023047;
  background-color: #1f2937;
  color: #d5d8db;
  font-weight: normal;
  padding: 0;
  width: $sidebar-width-lg;
  // width: 500px;
  z-index: 11;
  transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;

  .sidebar-brand-wrapper {
    transition: width $action-transition-duration
        $action-transition-timing-function,
      background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration
        $action-transition-timing-function,
      background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration
        $action-transition-timing-function,
      background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration
        $action-transition-timing-function,
      background $action-transition-duration $action-transition-timing-function;
    // background-color: rgba(0, 27, 11, 0.734);
    // background-color: #023047;
    background-color: #1f2937;
    color: #d5d8db;

    width: $sidebar-width-lg;
    height: $navbar-height;
    @media (max-width: 991px) {
      width: 55px;
    }
    .sidebar-brand {
      color: lighten(color(gray-dark), 20%);
      font-size: 1.5rem;
      line-height: 48px;
      margin-right: 0;
      // padding: 0rem 0rem 0rem 1.3rem;
      width: 100%;

      &:active,
      &:focus,
      &:hover {
        color: lighten(color(gray-dark), 10%);
      }

      img {
        width: calc(#{$sidebar-width-lg} - 120px);
        max-width: 100%;
        height: 28px;
        margin: auto;
        vertical-align: middle;
      }
      &.brand-logo-mini {
        display: none;
        img {
          width: calc(#{$sidebar-width-icon} - 50px);
          max-width: 100%;
          height: 28px;
          margin: auto;
        }
      }
    }
  }
  //new class added to nav bar -- to make it fixed

  .topheading {
    // box-sizing: border-box;
    // border: 2px solid aqua;
    position: fixed;
    top: 0px;
    width: $sidebar-width-lg;
    // padding-top: 2px;
    height: $navbar-height !important;
    display: flex;
    gap: 0.8rem;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgb(209, 204, 204);
    border-bottom: 1px solid rgb(209, 204, 204);
  }

  .nav-container {
    background: #1f2937;
    // width: 100%;
    width: $sidebar-width-lg;
    // border: 2px solid goldenrod;
    box-sizing: border-box;
    position: fixed;
    top: $navbar-height !important;
    // margin-top: $navbar-height !important;
    margin-top: 10px;
    max-height: calc(100% - $navbar-height);
    height: 100%;
    // max-height: 100vh;
    // height: 100vh;
    padding-bottom: 40px;
    overflow: auto;
    scroll-behavior: smooth;
  }

  .nav-container::-webkit-scrollbar {
    width: 8px;
  }

  .nav-container::-webkit-scrollbar-thumb {
    background: #51698b;
    border-radius: 4px;
    border-right: 2px solid #1f2937;
  }

  .nav {
    display: flex;
    // overflow: auto;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: start;
    margin-bottom: 60px;
    gap: 0.8rem;
    // padding-top: $navbar-height;
    padding-top: 0px;
    @media (max-width: 992px) {
      padding-top: 0;
    }

    .nav-item {
      // border: 2px solid red !important;
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      padding-right: 20px;
      .collapse {
        z-index: 999;
      }

      .nav-link {
        // border: 2px solid green !important;
        @include display-flex;
        @include align-items(center);
        white-space: nowrap;
        padding: $sidebar-menu-padding-y 10px $sidebar-menu-padding-y
          $sidebar-menu-padding-x;
        color: $sidebar-menu-color;
        color: white;

        @include transition-duration(0.45s);
        transition-property: color;
        -webkit-transition-property: color;
        height: 46px;
        border-radius: 0px 100px 100px 0px;
        cursor: pointer;

        i {
          color: inherit;
          &.menu-icon {
            font-size: $sidebar-icon-font-size;
            line-height: 1;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            color: $sidebar-menu-icon-color;

            &:before {
              vertical-align: middle;
            }
          }
          &.menu-arrow {
            font: normal normal normal 24px/1 "Material Design Icons";
            line-height: 1;
            font-size: $sidebar-icon-font-size;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            color: $sidebar-menu-arrow-color;
            &:before {
              content: "\f140";
              font-size: inherit;
              color: inherit;
            }
          }
        }
        .menu-title {
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
          // color: $sidebar-menu-color;
          color: inherit;
        }
        .badge {
          margin-right: auto;
          margin-left: 1rem;
        }
      }

      &.active {
        > .nav-link {
          // background: $sidebar-menu-active-bg;
          background-color: rgb(201, 148, 12);
          position: relative;
          &:before {
            content: "";
            width: 3px;
            height: 100%;
            background: theme-color(primary);
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
          }
          .menu-title {
            color: $sidebar-menu-active-color;
          }
          i {
            color: #ffffff;
          }
        }
      }
      &.nav-profile {
        .nav-link {
          height: auto;
          line-height: 1;
          border-top: 0;
          padding: 1.25rem 0;
          .nav-profile-image {
            width: 44px;
            height: 44px;
            img {
              width: 44px;
              height: 44px;
              border-radius: 100%;
            }
          }
          .nav-profile-text {
            margin-left: 1rem;
            .rtl & {
              margin-left: auto;
              margin-right: 1rem;
            }
          }
          .nav-profile-badge {
            font-size: 1.125rem;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }
      &.sidebar-actions {
        margin-top: 1rem;
        .nav-link {
          border-top: 0;
          display: block;
          height: auto;
        }
        &:hover {
          background: initial;
          .nav-link {
            color: initial;
          }
        }
      }
      &.profile {
        // border-bottom: 2px solid white;
        // box-shadow: 0px 0px 2px white;
        padding-right: 0px;
        margin-bottom: 20px;
        .rtl & {
          padding-right: 10px;
        }
        .profile-desc {
          // border: 2px solid green;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0.6rem 1.17rem;
          line-height: 1.25;
          .profile-name {
            margin-left: 1rem;
            .rtl & {
              margin-left: 0;
              margin-right: 1rem;
            }
            span {
              font-size: 18px;
              color: $text-muted;
              white-space: nowrap;
            }
            h5 {
              white-space: nowrap;
            }
          }
          .profile-pic {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.7rem;
            align-items: center;
            width: 100%;
            .count-indicator {
              // border: 2px solid green;
              // width: 100%;

              position: relative;
              .count-symbol,
              .count-number {
                position: absolute;
                border-radius: 100%;
                border: 2px solid $navbar-bg;
              }
              .count-symbol {
                top: 17px;
                right: -3px;
                width: 10px;
                height: 10px;
              }
              .count-number {
                min-width: 14px;
                height: 14px;
                font-size: 0.5rem;
                color: $white;
                bottom: 16px;
                right: -5px;
                line-height: 1;
                text-align: center;
              }
              &:after {
                display: none;
              }
              .count {
                position: absolute;
                left: 70%;
                width: 10px;
                height: 10px;
                color: $white;
                border-radius: 100%;
                text-align: center;
                font-size: 0.625rem;
                line-height: 1.5;
                top: 50px;
                border: 2px solid $border-color;
              }
            }
          }
          .mdi-dots-vertical {
            font-size: 20px;
            color: $text-muted;
          }
          .dropdown-menu {
            padding: 0;
            margin-top: 1.25rem;
            .dropdown-item {
              padding: 11px 13px;
              &.preview-item {
                align-items: center;
                .preview-thumbnail {
                  .preview-icon {
                    width: 30px;
                    height: 30px;
                    i {
                      font-size: 0.875rem;
                    }
                  }
                }
              }
              &:hover {
                color: inherit;
              }
            }
            .dropdown-divider {
              margin: 0;
            }
          }
        }
      }
      &.nav-category {
        padding: 0.5rem 1.188rem;
        line-height: 1;
        .nav-link {
          color: $text-muted;
          font-weight: $font-weight-medium;
          font-size: 14px;
          font-weight: 500;
          height: auto;
          padding-left: 0;
          cursor: default;
        }
      }
      .menu-icon {
        margin-right: 0.5rem;
        font-size: 0.8125rem;
        line-height: 1;
        background: rgba($text-muted, 0.2);
        width: 31px;
        height: 31px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background-color: rgb(221, 221, 221);
        i {
          font-size: 1.2rem;
        }
      }
    }
    &.sub-menu {
      margin-bottom: 0;
      margin-top: 0;
      padding: 1rem 0 0 3.25rem;
      list-style: none;

      .nav-item {
        // border: 2px solid green;
        padding: 0;
        .nav-link {
          // border: 2px solid red;
          // max-width: 200px;
          // text-wrap: wrap;
          color: $sidebar-submenu-color;
          // color: white;
          padding: $sidebar-submenu-item-padding;
          position: relative;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          border-top: 0;
          &.active {
            color: $sidebar-menu-active-color;
            // background: transparent;
            background: #debe6d;
          }
          &:hover {
            color: $sidebar-submenu-hover-color;
            background: $sidebar-menu-hover-bg;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
    &:not(.sub-menu) {
      > .nav-item {
        &:hover {
          &:not(.nav-category):not(.account-dropdown) {
            > .nav-link {
              background: $sidebar-menu-hover-bg;
              color: $sidebar-menu-hover-color;
            }
          }
        }
      }
    }
    .menu-items {
      &:nth-child(5n + 1) {
        .nav-link {
          .menu-icon {
            i {
              color: theme-color(primary);
            }
          }
        }
      }

      &:nth-child(5n + 2) {
        .nav-link {
          .menu-icon {
            i {
              color: #af067f;
            }
          }
        }
      }

      &:nth-child(5n + 3) {
        .nav-link {
          .menu-icon {
            i {
              color: #0b5920;
            }
          }
        }
      }

      &:nth-child(5n + 4) {
        .nav-link {
          .menu-icon {
            i {
              color: #004e89;
            }
          }
        }
      }

      &:nth-child(5n + 5) {
        .nav-link {
          .menu-icon {
            i {
              color: #6a040f;
            }
          }
        }
      }
    }
  }
}

// .sidebar-container {
//   border-top: 1px solid rgb(175, 170, 170);
//   // box-shadow: 0px 0px 2px white;
//   // position: fixed;
//   max-height: calc(100vh - #{$navbar-height});
//   top: $navbar-height;
//   bottom: 0;
//   overflow: auto;
//   left: 0;
//   -webkit-transition: all 0.25s ease-out;
//   -o-transition: all 0.25s ease-out;
//   transition: all 0.25s ease-out;
// }
// .sidebar-container {
//   border: 2px solid red;
//   width: 500px;
//   position: static;
// }

// .sidebar {
//   border: 2px solid goldenrod;
//   position: fixed;
//   top: 10px;
// }

.sidebar-offcanvas {
  // border-top: 1px solid rgb(175, 170, 170);
  // box-shadow: 0px 0px 2px white;
  // position: fixed;
  max-height: calc(100vh - #{$navbar-height});
  // margin-top: $navbar-height;
  bottom: 0;
  overflow: auto;
  left: 0;
  -webkit-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    // border: 2px solid blue;
    // z-index: 100;
    margin-top: 0px;
    position: fixed;
    // max-height: calc(100vh - #{$navbar-height});
    max-height: 100vh;
    top: $navbar-height;
    top: 0px;
    bottom: 0;
    overflow: auto;
    left: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      left: 0;
    }
  }
}

.brand-logo-mini img {
  display: none !important;
}
