/* Tables */

.table {
  margin-bottom: 0;
  color: $text-muted;
  thead {
    // margin-bottom: 4px;
    // border-radius: 10px 10px 0px 0px;
    th {
      font-family: $type1;
      font-weight: $font-weight-medium;
      font-size: 14px;
      background: #34435e;
      // border: 1px solid white;
      color: #c4c4c4;
      i {
        margin-left: 0.325rem;
      }
    }

    // th:nth-child(even) {
    //   background: $admin-theme-primary-bg;
    // }
  }

  td {
    vertical-align: middle;
    font-size: $default-font-size;
    font-size: 14px !important;
    line-height: 1;
    // text-align: center;
    img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
      font-size: 14px;
      padding: 10px 15px !important;
      color: white !important;
    }

    .nameButton {
      background-color: #e5e7eb;
      color: #1f2937;
      font-weight: bold;
      border-radius: 20px;
      padding: 5px 15px;
      font-size: 14px;
      color: rgb(37, 37, 177) !important;
      &:hover {
        // scale: 1.02;
        text-decoration: underline;
        // color: blue !important;
      }
    }
  }

  tr:nth-child(even) {
    background: $admin-theme-primary-bg;
  }
  tr {
    &:hover {
      background-color: #b1b5bb !important;
    }
  }

  &.table-borderless {
    border: none;
    tr,
    td,
    th {
      border: none;
    }
  }
}
