.commoncss-custom-class-width {
  /* border: 2px solid red !important; */
}

@media (max-width: 576px) {
  .commoncss-custom-class-width {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 1034px) {
  /* .commoncss-custom-with-below-800 {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 2px;
  } */
  .commoncss-custom-with-datediv {
    /* border: 2px solid red !important; */
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 0px !important;
    margin-bottom: 4px;
  }
}
