.profile-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.profile-card {
  background-color: #202938;
  border-radius: 10px;
  padding: 20px;
}

.image-section {
  text-align: center;
  margin-bottom: 20px;
}

.profile-image {
  border: 2px solid #ddd;
  padding: 10px;
  background-color: white;
  border-radius: 10%;
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  color: whitesmoke;
  margin-top: 10px;
  margin-left: 30px;
  font-size: 16px;
  font-weight: bold;
}

.form-group {
  margin-bottom: 15px;
}

.button {
  background-color: #344360;
  color: white;
  width: 100%;
  height: 40px;
  border-radius: 5px;
}
.button:hover {
  background-color: #2f446e;
}
.update {
  margin-left: 3vh;
  margin-bottom: 25px;
  font-size: 18px;
}
#update_admin_form label {
  font-weight: bold;
}
