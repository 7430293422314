.card-container {
  margin: 10px auto;
  /* position: relative; */
  overflow: hidden;
  border-radius: 5px;
  /* border: 2px solid gray; */
  padding: 0px;
  height: 100px;
  width: 46%;
  /* min-width: 300px; */
  /* font-family: monospace; */
  background-color: white;
  /* box-shadow: 1px 1px 4px green; */
  transition: all 0.25s ease-in-out;
}

.card-container:hover {
  scale: 1.01;
  background-color: rgb(252, 245, 245);
}

.card-container-body {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-click-button {
  position: absolute;
  top: 7px;
  right: 7px;
  color: green;
}

.card-click-button a {
  text-decoration: none !important;
}

.card-click-button-text {
  /* border: 1px solid green; */
  color: green;
  padding: 2px;
  border-radius: 4px;
}

.card-info-div {
  /* border: 2px solid goldenrod; */
  width: 90%;
  max-height: 90%;
  display: flex;
  align-items: center;
  gap: 2rem;
  overflow: hidden;
}

.card-info-details {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.card-info-logo {
  /* border: 1px solid green; */
  padding: 4px;
  border-radius: 4px;
  color: green;
}

.card-info-logo i {
  font-size: 45px;
  color: inherit;
}

.card-info-details span:first-child {
  font-size: 22px;
  font-weight: bold;
}
.card-info-details span:last-child {
  font-size: 18px;
}

.card-bottom-background {
  position: absolute;
  bottom: -20px;
  right: -20px;
  /* border: 1px solid gold; */
  transform: rotate(-30deg);
  z-index: 0;
  opacity: 0.55;
}

.card-bottom-background i {
  font-size: 50px;
  color: inherit;
}

@media (max-width: 720px) {
  .card-container {
    width: 100%;
  }
}
